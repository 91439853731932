import React from "react";
import { useTranslation } from "react-i18next";

const Uses = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#f3f3f3] py-12" id="applications">
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/3 bg-[#f3f3f3] lg:border-r-2 p-12 border-b-2 lg:border-b-0">
            <img
              src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/35d0f32e-0fef-4308-b872-0e8d28d712e5/d80aede-9cd1ccc4-2678-419b-9928-27e835ad68a5.png/v1/fill/w_1600,h_1073,strp/engranaje_png_by_turnlastsong_d80aede-fullview.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTA3MyIsInBhdGgiOiJcL2ZcLzM1ZDBmMzJlLTBmZWYtNDMwOC1iODcyLTBlOGQyOGQ3MTJlNVwvZDgwYWVkZS05Y2QxY2NjNC0yNjc4LTQxOWItOTkyOC0yN2U4MzVhZDY4YTUucG5nIiwid2lkdGgiOiI8PTE2MDAifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.wyxyvj_JHS6-Y9H0-Gr_X7pl0iCV8YCJEYifpiHK-XU"
              alt=""
            />
            <h1 className="font-semibold text-3xl text-center">
              {t("prototyping")}
            </h1>
            <p className="pt-6">{t("proto_desc")}</p>
          </div>
          <div className="lg:w-1/3 bg-[#f3f3f3] lg:border-r-2 p-12 border-b-2 lg:border-b-0">
            <img
              src="https://www.3dprinteros.com/images-printer/printer-3-char-1-preview_1573672450.png"
              alt=""
            />
            <h1 className="font-semibold text-3xl text-center">
              {t("reverse_eng")}
            </h1>
            <p className="pt-6">{t("reverse_desc")}</p>
          </div>
          <div className="lg:w-1/3 bg-[#f3f3f3] lg:border-r-2 p-12 lg:border-b-0 border-b-2">
            <img
              src="https://images.squarespace-cdn.com/content/v1/51b94f0ce4b01efb8e2bde98/1526512801753-OL51Y0CKWOT3HKD660ES/HP+Part.png"
              alt=""
            />
            <h1 className="font-semibold text-3xl text-center">{t("parts")}</h1>
            <p className="pt-6">{t("parts_desc")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uses;
