import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const Filaments = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#e5e8ee]" id="filaments">
      <div className="container py-16">
        <h1 className="font-semibold text-5xl pb-12">
          {t("3d_printing_filaments")}
        </h1>
        <p>{t("filaments_desc")}</p>

        <div className="flex flex-col lg:flex-row items-center justify-center">
          <div className="lg:w-1/5 lg:border-r-2 border-b-2 p-6">
            <img
              src="https://images.squarespace-cdn.com/content/v1/61fd5ebabb5c7f21203fcb29/1644945188134-LJE3VEYBNEKWMT1CX2V2/Helios%2BPeek_Card.jpg?format=300w"
              alt=""
              className="rounded-full"
            />
            <h1 className="font-semibold text-xl text-center">
              Helios Peek 2005
            </h1>
            <button
              type="button"
              class="mt-4 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900  focus:bg-gray-900  focus:outline-none focus:ring-0 active:bg-gray-900 g transition duration-150 ease-in-out"
            >
              <a href="https://www.wercomfg.com/s/TDS-HeliosPEEK-2005-rk6w.pdf">
                {t("more_info")}
              </a>
            </button>
          </div>
          <div className="lg:w-1/5 lg:border-r-2 border-b-2 p-6">
            <img
              src="https://images.squarespace-cdn.com/content/v1/61fd5ebabb5c7f21203fcb29/1644619395401-VG8X65P9SH0FM6X3AZJW/Carbon+Peek_Card.png?format=300w"
              alt=""
              className="rounded-full"
            />
            <h1 className="font-semibold text-xl text-center">Carbon PEEK</h1>
            <button
              type="button"
              class="mt-4 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900  focus:bg-gray-900  focus:outline-none focus:ring-0 active:bg-gray-900 g transition duration-150 ease-in-out"
            >
              <a href="https://www.wercomfg.com/s/CarbonPeek-Specs.pdf">
                {t("more_info")}
              </a>
            </button>
          </div>
          <div className="lg:w-1/5 lg:border-r-2 border-b-2 p-66">
            <img
              src="https://images.squarespace-cdn.com/content/v1/61fd5ebabb5c7f21203fcb29/1644619419302-87KWCP7VGSDEQMUTMZN8/PEEK_Card.png?format=300w"
              alt=""
              className="rounded-full"
            />
            <h1 className="font-semibold text-xl text-center">PEEK</h1>
            <button
              type="button"
              class="mt-4 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900  focus:bg-gray-900  focus:outline-none focus:ring-0 active:bg-gray-900 g transition duration-150 ease-in-out"
            >
              <a href="https://www.wercomfg.com/s/Peek-Specs.pdf">
                {t("more_info")}
              </a>
            </button>
          </div>
          <div className="lg:w-1/5 lg:border-r-2 border-b-2 p-6">
            <img
              src="https://images.squarespace-cdn.com/content/v1/61fd5ebabb5c7f21203fcb29/1644897206756-4BB7HPEJZ07Z9B1BHGTL/Carbon+PA_Card.png?format=300w"
              alt=""
              className="rounded-full"
            />
            <h1 className="font-semibold text-xl text-center">Carbon PA</h1>
            <button
              type="button"
              class="mt-4 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900  focus:bg-gray-900  focus:outline-none focus:ring-0 active:bg-gray-900 g transition duration-150 ease-in-out"
            >
              <a href="https://www.wercomfg.com/s/CarbonPA-Specs.pdf">
                {t("more_info")}
              </a>
            </button>
          </div>
          <div className="lg:w-1/5 lg:border-r-2 border-b-2 p-6">
            <img
              src="https://images.squarespace-cdn.com/content/v1/61fd5ebabb5c7f21203fcb29/1644619487739-Q29IF0PMT1AZ4A8CHRXU/Ultem_Card.png?format=300w"
              alt=""
              className="rounded-full"
            />
            <h1 className="font-semibold text-xl text-center">ULTEM</h1>
            <button
              type="button"
              class="mt-4 inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-gray-900  focus:bg-gray-900  focus:outline-none focus:ring-0 active:bg-gray-900 g transition duration-150 ease-in-out"
            >
              <a href="https://www.wercomfg.com/s/Ultem-Specs.pdf">
                {t("more_info")}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filaments;
