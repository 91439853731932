import React from "react";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="container lg:pt-12">
        <div className="flex lg:flex-row flex-col items-center">
          <div className="lg:w-1/2 ">
            <h1 className="font-semibold lg:text-6xl lg:text-left">
              {t("hero_title")}
            </h1>
            <p className="lg:text-left lg:pr-5 pt-6">{t("hero_message")}</p>
          </div>
          <div className="lg:w-1/2">
            <img
              src="https://www.roboze.com/stampantiImg/3_videocover_1_0_1616841029.png"
              alt="printer"
              className="mt-32 lg:mt-0 mb-6 lg:mb-0"
            />
          </div>
          <div className="lg:hidden">
            <h1 className="font-semibold text-5xl lg:text-6xl lg:text-left">
              {t("hero_title")}
            </h1>
            <p className="lg:text-left lg:pr-5 lg:pt-6 pt-4 lg:pb-0 pb-12">
              {t("hero_message")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
